import $ from 'jquery';

$(".sp_btn_company").on("click", function(){
    $(".sp_company_btns").stop().slideToggle();
    $(".sp_application_btns").slideUp();
})

$(".sp_btn_application").on("click", function(){
    $(".sp_application_btns").stop().slideToggle();
    $(".sp_company_btns").slideUp();
})
